<template>
    <el-container class="h-100">
      <meta name="viewport" content="width=device-width,user-scalable=no,initial-scale=1.0">
        <div class="h-100 w-500">
          <header class="h5_header">
            <h1>{{ h5Data.doc_header.doc_title }}</h1>
            <p>{{ h5Data.doc_header.doc_subtitle }}</p>
          </header>
          <div class="h5_nav mar-t-15">
            <a @click="handleClick('gs')" class="active">概述</a>
            <a @click="handleClick('tszl')">舆情态势纵览</a>
            <a @click="handleClick('hyjp')">行业竞品舆情态势</a>
          </div>
          <div v-if="activeIndex == 'gs'" class="gs_cont">
              <div class="h5_card">
                  <p>{{ h5Data.section0.paragraph1 }}</p>
                  <p>{{ h5Data.section0.paragraph2 }}</p>
              </div>
          </div>
          <div v-if="activeIndex == 'tszl'" class="tszl_cont">
              <div class="h5_card mar-t-15">
                  <h3>时间段：{{ h5Data.section1.span }}</h3>
              </div>
              <div class="h5_card mar-t-15">
                  <h3>（一）监测关键词</h3>
                  <dl class="mar-t-15">
                      <div v-for="(item, index) in h5Data.section1.words" :key="index">
                        <dt>{{ item.key }}</dt>
                        <dd>{{ item.words }}</dd>
                      </div>
                  </dl>
              </div>
              <div class="h5_card mar-t-15">
                  <h3>（二）日信息量</h3>
                  <p>
                    <strong> {{ h5Data.section1.info.total }}：</strong>
                    {{ h5Data.section1.info.stat }}
                  </p>
              </div>
              <div class="h5_card mar-t-15">
                  <h3>（三）信息声量走势</h3>
                  <p>{{ h5Data.section1.trend.text }}</p>
                  <!-- 信息声量走势 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>信息声量走势</span></h2>
                    <div class="map" id="voicetrend"></div>
                    <div class="echarts_empty hide">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 信息声量走势 -->
              </div>
              <div class="h5_card mar-t-15">
                  <h3>（四）信息属性概况</h3>
                  <p>{{ h5Data.section1.emotion.text }}</p>
                  <!-- 敏感信息占比 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>敏感信息占比</span></h2>
                    <div class="map" id="sensitive"></div>
                    <div class="echarts_empty hide">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 敏感信息占比 -->
              </div>
              <div class="h5_card mar-t-15" v-if="h5Data.section1.pos_msg && Object.prototype.toString.call(h5Data.section1.pos_msg) === '[object Object]'">
                  <h3>（五）主要正面舆情焦点</h3>
                  <h3>1、{{ h5Data.section1.pos_msg.title }}</h3>
                  <p class="summary">
                    <span class="zm">正面</span>
                    {{ h5Data.section1.pos_msg.desc }}
                  </p>
                  <p>{{ h5Data.section1.pos_msg.total }}</p>
                  <a :href="h5Data.section1.pos_msg.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
              </div>
              <div class="h5_card mar-t-15" v-else>
                <h3>（五）主要正面舆情焦点</h3>
                <p>暂无数据</p>
              </div>
              <div class="h5_card mar-t-15" v-if="h5Data.section1.neg_msg && Object.prototype.toString.call(h5Data.section1.neg_msg) === '[object Object]'">
                  <h3>（六）主要负面舆情焦点</h3>
                  <h3>1、{{ h5Data.section1.neg_msg.title }}</h3>
                  <p class="summary">
                    <span class="fm">负面</span>
                    {{ h5Data.section1.neg_msg.desc }}
                  </p>
                  <p>{{ h5Data.section1.neg_msg.total }}</p>
                  <a :href="h5Data.section1.neg_msg.source_url" target="_blank" class="more">查看原文 &gt;&gt;</a>
              </div>
              <div class="h5_card mar-t-15" v-else>
                <h3>（六）主要负面舆情焦点</h3>
                <p>暂无数据</p>
              </div>
              <div class="h5_card mar-t-15">
                  <h3>（七）内容传播分析</h3>
                  <p>{{ h5Data.section1.content.words.pos_words }}, {{ h5Data.section1.content.words.neg_words }}</p>
                  <!-- 高频情感词汇 -->
                  <div class="h5_card mar-t-15">
                      <h2 class="tit"><span>高频情感词汇</span></h2>
                    <div class="map" id="words" v-show="h5Data.section1.content.diagram.length >= 1"></div>
                    <div class="echarts_empty" v-if="h5Data.section1.content.diagram.length < 1">
                        <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
                    </div>
                  </div>
                  <!-- 高频情感词汇 -->
              </div>
          </div>
          <div v-if="activeIndex == 'hyjp'" class="hyjp_cont">
              <div class="h5_card mar-t-15" v-for="(item, index) in h5Data.section2" :key="index">
                  <h3>（{{ numberMap[index] }}）{{ item.name }}</h3>
                  <p>日信息总量：{{ item.data.total }}</p>
                  <div class="flex">
                    <p>情感分布：</p>
                    <div>
                      <p>{{ item.data.percent.pos_percent }}</p>
                      <p>{{ item.data.percent.neg_percent }}</p>
                    </div>
                  </div>
                  <p>热点信息（包含正负面）：</p>
                  <h3>1、{{ item.data.title }}</h3>
                  <p class="summary">
                    <span class="fm" v-if="item.data.content.emotion_i < 50">负面</span>
                    <span class="zm" v-else>正面</span>
                    {{ item.data.abstract }}
                  </p>
                  <p class="xss">相似数：{{ item.data.info_num }}篇</p>
                  <a :href="item.data.content.source_url_s" target="_blank" class="more">查看原文 &gt;&gt;</a>
              </div>
              <div class="echarts_empty" v-show="!h5Data.section2">
                <img src="/static/img/echarts_empty.png" alt="暂无数据" height="120" type="image/svg+xml">
              </div>
          </div>
          <footer class="h5_footer" v-if="version === 'common'">数知舆情管理系统 版权所有 </footer>
        </div>
    </el-container>
</template>
<script>
  import { globalGetEcharts, globalCreateChart } from "@/utils/helpers";
  import { detail } from "@/api/briefing";
  const d3 = require("d3");
  export default {
    name: "component-briefh5-mix",
    components: {
    },
    data() {
      return {
        activeIndex: "gs",
        h5Data: {},
        numberMap: ['一', '二', '三', '四', '五', '六'],
        version: process.env.VUE_APP_VERSION
      }
    },
    mounted() {
      this.loadData()
    },
    methods:{
      loadData() {
        const {id} = this.$route.query;
        if (!id) {
          this.$message.error('不存在该简报');
          return false;
        }
        detail({id: id}).then(res => {
          const {state, data} = res.data;
          if (state) {
            this.h5Data = JSON.parse(data.data);
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.console.error(err)
        })
      },
      handleClick(index) {
        this.activeIndex = index
        let indexMap = ["gs", "tszl", "hyjp"]
        if (index === 'tszl') {
          this.$nextTick(() => {
            this.voicetrend()
            this.sensitive()
            this.wordPop('words')
          })
        }
        _(indexMap).forEach((v, k) => {
          window.jQuery(".h5_nav a").removeClass("active")
          if (v == index) {
            window.jQuery(".h5_nav a:eq(" + k + ")").addClass("active")
            return false
          }
        })
      },
      voicetrend() {
        let echarts = globalGetEcharts()
        let option = {
          tooltip : {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            top:"5px",
            icon: "circle",
            itemGap:20,
            itemWidth:10,
            itemHeight:10,
            show: false
          },
          grid: {
            left: '8%',
            right: '4%',
            top: '20px',
            bottom: '20px',
            width:"90%",
            containLabel: true
          },
          xAxis : {
            type : 'category',
            boundaryGap : false,
            data: []
          },
          yAxis: {
//            type : 'value',
            axisLabel: {
              formatter: function (value) {
                return value;
              }
            }
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color:"#65A4FB",
          series : [{
            name: "数据总量",
            type: 'line',
            smooth: true,
            // showSymbol: false,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0,0,0,1,[{
                  offset: 0,
                  color: '#65A4FB'
                }, {
                  offset: 1,
                  color: '#E4F0FF'
                }])
              }
            },
            label: {
              normal: {
                show: true,
                position: 'top',
                formatter: function (params) {
                  return params.data.name
                }
              },
            },
            data: []
          }]
        }
        let result = this.h5Data.section1.trend.diagram;
        option.xAxis.data = result.keys.map(function (item, index) {
          let temp = item.split("-")
          temp.shift()
          return temp.join("-")
        })
        option.series[0].data = result.data.map((v, index) => {
          if (result.points.peak.indexOf(index) !== -1) {
            return {
              name: '',
              symbol: 'image:///static/img/dian_lan.svg',
              symbolSize: 10,
              value: v
            }
          } else if (result.points.troughs.indexOf(index) !== -1) {
            return {
              name: '',
              symbol: 'image:///static/img/dian_lan.svg',
              symbolSize: 10,
              value: v
            }
          } else {
            return {
              name: '',
              value: v
            }
          }
        })
        let element = window.document.getElementById('voicetrend')
        if (!element) return false // 切换时直接break
        var chart = globalCreateChart(element, option)
        this.$nextTick(() => {
          window.addEventListener("resize", () => { chart.resize();})
        })
      },
      sensitive() {
        var result = this.h5Data.section1.emotion.diagram;
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)"
          },
          legend: {
            // orient: 'vertical',
            // x: 'left',
            data:['敏感','非敏感'],
            top:"10px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon:"circle"
          },
          color: ['#FC5D73','#555DFE'],
          series: [
            {
              name:'情感分布',
              type:'pie',
              center:['50%', '60%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '30',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: [{name: "敏感", value: result.neg_total}, {name: "非敏感", value: result.pos_total}]
            }
          ]
        }
        let element = window.document.getElementById('sensitive')
        if (!element) return false // 切换时直接break
        var chart = globalCreateChart(element, option)
        this.$nextTick(() => {
          window.addEventListener("resize", () => { chart.resize();})
        })
      },
      wordPop(id) {
        var data = this.h5Data.section1.content.diagram;
        if (data.length < 1) return false;
        var maxr = data.length >= 1 ? data.sort(function(a, b) { return b.word_count - a.word_count })[0].word_count : 0
        function typeStr(obj){
          return Object.prototype.toString.call(obj).toLowerCase();
        }
        function Bubble(){
          var tempObject = new Object();
          tempObject.setOption = function (option) {
            this.width  = option.width;
            this.height = option.height;
            this.padding= option.padding;
            this.data   = option.data;//数据url或对象,必填
            this.conEle = option.conEle;//svg容器(node或者选择器)，必填
          }
          tempObject.init = function(){
            var that = this;
            //1.设置颜色
            var color = d3.scale.category20c();
            //2.布局
            var bubble = d3.layout.pack()
              .sort(null)
              .size([that.width,that.height])
              .radius(function (d) {
                var val = d / maxr * 50
                return val < 20 ? 20 : val
              })
              .padding(that.padding);
//          //3.添加svg元素
            var svg = d3.select(that.conEle).append("svg")
              .attr("width", that.width)
              .attr("font-size", '12')
              .attr("height", that.height);
            //4.数据请求及图形绘制
            if(typeStr(that.data)=='[object string]'){
              d3.json(that.data,function(error,data){
                if(error) throw error;
                //1.对数据进行处理
                data = dataHandle(data);
                render(svg,bubble,that,data);
              })
            }else{
              render(svg,bubble,that,dataHandle(that.data));
            }
          }
          return tempObject;
        }
        //Returns a flattened hierarchy containing all leaf nodes under the root.
        function classes(root){
          var classes = [];                                                                                        //存储结果的数组
          /*
           * 自定义递归函数
           * 第二个参数指传入的json对象
           */
          function recurse(name, node) {
            if (node.children)                                                                                   //如果有孩子结点 （这里的children不是自带的，是json里面有的）
            {
              node.children.forEach(function(child) {                                                          //将孩子结点中的每条数据
                recurse(node.name, child);
              })
            }
            else {
              //如果自身是孩子结点的，将内容压入数组
              classes.push({ name: node.name, value: node.size,props:node.props})
            };
          }
          recurse(null, root);
          return {children: classes};
        }
        function render(view,layout,context,data,cb){
          var node = view.selectAll(".node")
          //绑定数据（配置结点）
            .data(layout.nodes(classes(data))
              .filter(function(d) {
                //数据过滤，满足条件返回自身（没孩子返回自身，有孩子不返回，这里目的是去除父节点）
                return !d.children;
              }))
            .enter().append("g")
            .attr("class", "node")
            .attr("transform", function(d) {
              //设定g移动
              return "translate(" + d.x + "," + d.y + ")";
            });
          var usingNodes = node.filter(function(d){
            return d.props.using;
          });
          var time = +new Date();
          var duration = 1000;
          var strokeWidth =0;
          node.append("circle")
            .attr("r", function(d) {
              //设置圆的半径
              return d.r;
            })
            .style("fill", function(d) {
              //气泡颜色
              return d.props.color;
            })
            .style("fill-opacity",1);
          node.append("text")
            .attr("dy", ".3em")
            //设置文本对齐
            .style("text-anchor", "middle")
            .style("font-size",'12px')
            .style("fill", function (d) {
              //字体颜色
              return "#fff"
            })
            //根据半径的大小来截取对应长度字符串(很重要)
            .text(function(d) {
              return d.name.substring(0, d.r / 8);
            });
          function animate(){
            var nowTime = +new Date();
            if((nowTime-duration) > time) {
              time = nowTime;
              strokeWidth = 0;
            }
            strokeWidth += 0.6;
            //strokeWidth >10?strokeWidth=10:strokeWidth += 1;
            usingNodes.select("circle")
              .style("stroke-width",strokeWidth+'px')
              .style("stroke-opacity",'0.3')
              .style("stroke",function(d){
                return d.props.color;
              });
//          requestAnimationFrame(animate);
          }
          animate();
        }
        //定义数据处理方法
        function dataHandle(data){
          var result = {
            name:"flare",
            children:[]
          }
          data.forEach(function(ele){
            result.children.push({
              name:ele.name,
              size:ele.value,
              props:ele.props
            });
          });
          return result;
        }
        var chartData = [];
        _(data).forEach((item, i) => {
          chartData.push({
            name: item.word,
            value: item.word_count,
            props: {
              abnormal: false,
              color: item.emotion == "pos" ? "#66a5ff" : "#fa3851",
              using: false
            }
          })
        })
        var element = window.document.getElementById(id)
        if (!element) return false // 切换时直接break
        var option = {
          data: chartData,
          conEle:'#'+id,
          width: 450,
          height: 300,
          padding:2
        }
        var bubble = new Bubble()
        bubble.setOption(option)
        bubble.init()
      }
    }
  }
</script>
<style scoped>
.w-500{width: 100%;}
.map{height: 300px;}
.h5_header{background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);width:100%;text-align: center;overflow: hidden;color:#fff;padding:10px 0;}
.h5_header h1{font-size:16px;line-height:40px;color:#fff;font-weight: 600;}
.h5_header p{line-height:20px;font-size:12px;color:#fff;}
.h5_nav{margin:15px 15px 0;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;}
.h5_nav a{height:30px;line-height:30px;background:#e5e5e5;font-size:12px;border-radius:15px;color:rgba(0,0,0,.5);display: inline-block;padding:0 15px;cursor: pointer;font-weight: 600;}
.h5_nav a.active{color:#fff;background-image: linear-gradient(90deg, #009ef6 0%, #5060d6 100%);}
.gs_cont,.tszl_cont,.hyjp_cont{padding:15px;}
.h5_card{background-color: #ffffff;box-shadow:0px 2px 8px 0px rgba(194,194,194,0.2);border-radius:3px;padding: 15px;}
.h5_card h3,.h5_card p{font-size:12px;line-height:24px;color:rgba(0,0,0,.8);}
.h5_card h3,.tszl_cont dt{font-weight:600;}
.h5_card p{text-indent: 2em;color:rgba(51,51,51,.8);}
.h5_card .tit {border-left:2px solid #555DFE;padding-left:15px;font-weight:600;font-size:14px;line-height:14px;color:#000;}
.h5_card .tit .cor_f00{color:red;}
@media screen and (min-width:500px) {
  .w-500{width:500px;margin:0 auto;}
}
.tszl_cont dt,.tszl_cont dd{line-height:20px;color:rgba(0,0,0,.8);font-size: 14px;}
.tszl_cont dd{border-bottom: 1px solid rgba(170,170,170,.1);margin-bottom:15px;padding-bottom:15px;font-size: 12px;}
.tszl_cont dd:last-child{border:none;}
.hide{display: none;}
.zm,.fm{border-style: solid;border-width:1px;text-align: center;border-radius:3px;font-size:12px;padding:3px 10px;color: #fff;margin:0 5px;line-height:22px;}
.tszl_cont .summary,.hyjp_cont .summary{display: -webkit-box !important;overflow: hidden;text-overflow: ellipsis;word-break: break-all;-webkit-box-orient:vertical;-webkit-line-clamp:6;margin-bottom:15px;}
.zm{color: #000dfe;border-color: #000dfe;}
.xss{border-top: 1px solid rgba(170,170,170,.1);padding-top:15px;}
.fm{color:#FF0000;border-color:#FF0000;}
a.more{text-align: center;font-size:12px;line-height:50px;color:#000;width: 100%;display: block;font-weight: 600;}
.h5_footer{text-align: center;font-size:12px;line-height:60px;color:rgba(0,0,0,.26);}
</style>